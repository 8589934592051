<template>
  <!-- sidebar -->
  <nav class="sidebar bg-white dark:bg-gray-800">
    <!-- sidebar head -->
    <div class="sidebar-head p-4">
      <router-link to="/instance-management" exact> </router-link>
      <button class="lg:hidden block dark:text-gray-400 float-right -mt-7" @click="$emit('sidebarToggle')">
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="25px" height="25px"
          preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
          <path fill="currentColor"
            d="M7.219 5.781L5.78 7.22L14.563 16L5.78 24.781l1.44 1.439L16 17.437l8.781 8.782l1.438-1.438L17.437 16l8.782-8.781L24.78 5.78L16 14.563z" />
        </svg>
      </button>
      <h2 class="text-2xl font-normal text-gray-800 dark:text-gray-500" translate="no">
        <img src="../assets/headless.png" />
        <span class="text-primary"></span>
        <span class="bg-gray-700 absolute mt-2 dark:block hidden rounded-md py-1 px-2 text-xs text-gray-200">Dark
          mode</span>
      </h2>
    </div>
    <!-- sidebar list -->
    <div class="sidebar-list p-4 mt-4">
      <p class="font-medium text-gray-400 dark:text-gray-600">Menu</p>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <!-- <div class="item">
          <router-link
            to="/"
            exact
            class="
              w-full
              flex
              text-left
              rounded-md
              box-border
              p-3
              hover:bg-gray-100
              dark:hover:bg-gray-700
            "
          >
            <span class="mr-3 text-xl"><Icon icon="bi:speedometer2" /></span>
            <span class="w-full"> Dashboard </span>
          </router-link>
        </div> -->
        <div class="item mt-3">
          <router-link to="/instance-management" exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
            <span class="mr-3 text-xl">
              <Icon icon="bi:stack" />
            </span>
            <span class="w-full"> Instance Management</span>
            <!-- <span class="box-border mt-1 text-gray-500"> </span> -->
          </router-link>
        </div>
        <div class="item mt-3">
          <router-link to="/application-control" exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
            <span class="mr-3 text-xl">
              <Icon icon="bi:window-stack" />
            </span>
            <span class="w-full"> Application Control </span>
            <span class="box-border mt-1 text-gray-500"> </span>
          </router-link>
        </div>
        <div class="item mt-3" @click="eventClickHandler">
          <router-link to="/events" exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
            <span class="mr-3 text-xl">
              <Icon icon="icon-park-outline:log" />
            </span>
            <span class="w-full"> Events</span>
            <!-- <span class="box-border mt-1 text-gray-500"> </span> -->
          </router-link>
        </div>
        <!-- <div class="item mt-3" @click="eventClickHandler" v-if="useradmin">
          <router-link to="/config-management" exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
            <span class="mr-3 text-xl">
              <Icon icon="ep:setting" />
            </span>
            <span class="w-full">Config Management</span>
          </router-link>
        </div>

        <div class="item mt-3" @click="eventClickHandler" v-if="useradmin">
          <router-link to="/srt-management" exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
            <span class="mr-3 text-xl">
              <Icon icon="ep:setting" />
            </span>
            <span class="w-full">SRT Management</span>
          </router-link>
        </div> -->
        

        <!-- <div class="item mt-3" @click="eventClickHandler" v-if="useradmin">
          <router-link to="/user-management" exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
            <span class="mr-3 text-xl">
              <Icon icon="codicon:organization" />
            </span>
            <span class="w-full">User Management</span>
          </router-link>

        </div> -->
        <div class="item mt-3" @click="eventClickHandler">
          <router-link to="/profile" exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
            <span class="mr-3 text-xl">
              <Icon icon="octicon:person-24" />
            </span>
            <span class="w-full">Profile</span>
            <!-- <span class="box-border mt-1 text-gray-500"> </span> -->
          </router-link>
        </div>
        <div class="item mt-3" v-if="useradmin">
          <menu-accordion>
            <template v-slot:icon>
              <Icon icon="ep:setting" />
            </template>
            <template v-slot:title> Management tools </template>
            <template v-slot:content>

              <div class="item mt-3">
                <router-link to="/srt-management" exact
                  class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
                  <span class="mr-3 text-xl">
                    <Icon icon="ep:setting" />
                  </span>
                  <span class="w-full">SRT Management</span>
                </router-link>
              </div>


              <div class="item mt-3">
                <router-link to="/config-management" exact
                  class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
                  <span class="mr-3 text-xl">
                    <Icon icon="ep:setting" />
                  </span>
                  <span class="w-full">Config Management</span>
                </router-link>
              </div>

              <div class="item mt-3">
                <router-link to="/user-management" exact
                  class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700">
                  <span class="mr-3 text-xl">
                    <Icon icon="codicon:organization" />
                  </span>
                  <span class="w-full">User Management</span>
                </router-link>
              </div>
            </template>
          </menu-accordion>
        </div>
        <div class="item mt-3"></div>
        <div style="border-top-width: calc(1px*(.5 - var(--tw-divide-y-reverse)));"></div>
        <div class="item mt-3 w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          id="clickable" @click="signOut">
          <span class="mr-3 text-xl">
            <Icon icon="bi:door-open" />
          </span>
          <span class="w-full"> Sign Out </span>
          <span class="box-border mt-1 text-gray-500"> </span>
        </div>
        <!-- <amplify-sign-out @click="$router.push('/login')"
          ><Icon icon="bi:pip"
        /></amplify-sign-out> -->
      </div>
    </div>
  </nav>
</template>
<style></style>
<script>
import { Icon } from "@iconify/vue";
import MenuAccordion from "./MenuAccordion.vue";
import { Auth } from "aws-amplify";
import store from "../store/index.js";
import { notification } from "ant-design-vue";

export default {
  components: {
    Icon,
    MenuAccordion,
  },
  data() {
    return {
      useradmin: null,
    };
  },

  watch: {
    "$store.state.notifications.notifications.newNotifications": function () {
      if (this.$store.state.user.user != null) {
        if (!this.$store.state.notifications.notifications.all) {
          return;
        } else if ( this.$store.state.notifications.notifications.all[0].eventSource == "Warning" && this.$store.state.notifications.notifications.all[0].org == store.state.user.organisation ) {
          notification["warning"]({
            message: this.$store.state.notifications.notifications.all[0].eventSource,
            description: this.$store.state.notifications.notifications.all[0].text,
            duration: 5,
          });
        } else if( this.$store.state.notifications.notifications.all[0].eventSource == "Error" && this.$store.state.notifications.notifications.all[0].org == store.state.user.organisation ) {
          notification["error"]({
            message: this.$store.state.notifications.notifications.all[0].eventSource,
            description: this.$store.state.notifications.notifications.all[0].text,
            duration: 0,
          });
        }
      } else {
        return;
      }
    },
    "$store.state.user.groups": function () {
      if (this.$store.state.user.user != null) {
        if (this.$store.state.user.groups == undefined) {
          return;
        } else if (this.$store.state.user.selectedRole.includes("Admin")) {
          this.useradmin = true;
        } else {
          this.useradmin = false;
        }
      } else {
        return;
      }
    },
  },
  methods: {
    sidebarToggle: function () {
      document.querySelector("flex-sidebar").classList.add("hidden");
    },
    async eventClickHandler() {
      await store.dispatch("notifications/resetNotifications", null, { root: true });
    },
    async signOut() {
      try {
        await Auth.signOut({ global: true });
        await store.dispatch("notifications/unsubscribeNotifications", null, {
          root: true,
        });
        await store.dispatch("user/logoutUser");
        this.$router.push("/login");
      } catch (error) {
        await Auth.signOut()
        await store.dispatch("notifications/unsubscribeNotifications", null, {
          root: true,
        });
        console.log("error signing out: ", error);
        this.$router.push("/login");
      }
    },
  },
  mounted() {
    if (this.$store.state.user.user != null) {
      if (this.$store.state.user.selectedRole.includes("Admin")) {
        this.useradmin = true;
      } else {
        this.useradmin = false;
      }
    }
  },
};
</script>
