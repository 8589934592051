export default {
	pitches: [
		{
			"Name": "FA",
			"Screen": "FASTBALL"
		},
		{
			"Name": "BK",
			"Screen": "BREAKING"
		},
		{
			"Name": "FF",
			"Screen": "4-SEAM",
			"alternate": "FASTBALL"
		},
		{
			"Name": "FT",
			"Screen": "2-SEAM",
			"alternate": "FASTBALL"
		},
		{
			"Name": "FC",
			"Screen": "CUTTER",
			"alternate": "FASTBALL"
		},
		{
			"Name": "SI",
			"Screen": "SINKER",
			"alternate": "FASTBALL"
		},
		{
			"Name": "FS",
			"Screen": "SPLITTER",
			"alternate": "OFFSPEED"
		},
		{
			"Name": "FO",
			"Screen": "FORKBALL",
			"alternate": "OFFSPEED"
		},
		{
			"Name": "CH",
			"Screen": "CHANGEUP",
			"alternate": "OFFSPEED"
		},
		{
			"Name": "SC",
			"Screen": "SCREWBALL",
			"alternate": "OFFSPEED"
		},
		{
			"Name": "SL",
			"Screen": "SLIDER",
			"alternate": "BREAKING"
		},
		{
			"Name": "CU",
			"Screen": "CURVEBALL",
			"alternate": "BREAKING"
		},
		{
			"Name": "CS",
			"Screen": "SLOW CURVE",
			"alternate": "BREAKING"
		},
		{
			"Name": "KC",
			"Screen": "KNUCKLE CURVE",
			"alternate": "BREAKING"
		},
		{
			"Name": "SV",
			"Screen": "SLURVE",
			"alternate": "BREAKING"
		},
		{
			"Name": "ST",
			"Screen": "SWEEPER",
			"alternate": "BREAKING"
		},
		{
			"Name": "GY",
			"Screen": "GYROBALL",
			"alternate": "OFFSPEED"
		},
		{
			"Name": "KN",
			"Screen": "KNUCKLEPITCH",
			"alternate": "BREAKING"
		},
		{
			"Name": "EP",
			"Screen": "EEPHUS PITCH",
			"alternate": "BREAKING"
		}
	]
}