// dev GraphQL
export default {
    Amplify: {
        Auth: {
            //dev
            // region: 'us-east-1',
            // userPoolId: 'us-east-1_VoW2dtLED',
            // userPoolWebClientId: '4lv23l2giia721gsdgs4rcvqv4',
            // identityPoolId: 'us-east-1:47dc24e3-c722-4812-8841-9d07bb9159f0',

            //prod
            region: 'us-east-1',
            userPoolId: 'us-east-1_GxidSXzpx',
            userPoolWebClientId: '21pirvdk9rmf4gbtkfpn6jj5rt',
            identityPoolId: 'us-east-1:0d40bd0c-d03d-4bef-b497-cdc56cf7e844',
        }
    },
    GraphQL: {
        //prod
        'aws_appsync_graphqlEndpoint': 'https://r6f7uwbdi5dqpoao6mtubonanm.appsync-api.us-east-1.amazonaws.com/graphql',
        //dev
        //'aws_appsync_graphqlEndpoint': 'https://oohh2ba6ifhibegtu4fatdmvlm.appsync-api.us-east-1.amazonaws.com/graphql',

        'aws_appsync_region': 'us-east-1',
        'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS'
    },
    Iot: {
        //prod
        endpoint: 'wss://a305yhtbj6kn2l-ats.iot.us-east-1.amazonaws.com/mqtt',
        
        //dev
        // endpoint: 'wss://a2crprh43ogz31-ats.iot.us-east-1.amazonaws.com/mqtt',

        policyName: 'FrontSubscribeReceiverPolicy',
        topicName: 'headless-test-frontComm-',
        region: 'us-east-1'
    }
}