import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Icon } from "@iconify/vue";
import VueApexCharts from "vue3-apexcharts";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import Amplify from 'aws-amplify';
import {PubSub} from "@aws-amplify/pubsub";
import store from './store';
import awsconfig from './aws-exports';
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import Loading from 'vue-loading-overlay';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// import Vue from 'vue'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'




// import "flowbite";


import "./assets/tailwind.css";
import "./assets/animate.css";
import "./assets/sass/css/windzo.css";
import {
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader';

Amplify.configure(awsconfig.Amplify);
PubSub.configure(awsconfig.GraphQL);

//API.configure(config);
Amplify.configure(awsconfig.GraphQL);
applyPolyfills().then(() => {
  defineCustomElements(window);
});
const app = createApp(App).use(Antd);
app.config.isCustomElement = tag => tag.startsWith('amplify-');
app.use(router, Icon);
app.use(VueApexCharts);
app.use(store)
app.use(Loading);
app.use(PerfectScrollbar);
app.mount("#app");


router.beforeEach((to, from, next) => {
  document.querySelector(".flex-sidebar").classList.add("hidden");
  next();
});
