<template>
  <div class="accordion h-auto p-3">
    <div class="w-full">
      <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">
        Events
      </h1>
      <div class="flex-top" style="display: flex">
        <div style="width: 50%; float: left">
          <p class="mt-1 text-sm font-normal text-gray-400">Headless Events</p>
        </div>
        <div
          class="demo-dropdown-wrap"
          style="margin-left: auto"
          @mouseover="checkBoxListen()"
        ></div>
      </div>
    </div>
    <div class="grid lg:grid-cols-1 grid-col-1 gap-4 mt-5">
      <div
        class="
          card
          col-span-1
          grid grid-cols-1
          gap-5
          bg-white
          dark:bg-gray-800
          w-full
          rounded-md
          p-5
          shadow
        "
      >
        <div class="accordion-packed">
          <h2 style="display: flex" class="font-semibold dark:text-gray-200">
            Event logs
          </h2>
          <div class="wrapper mt-5">
            <a-list
              class="demo-loadmore-list"
              :loading="initLoading"
              item-layout="horizontal"
              :data-source="list"
            >
              <template #loadMore>
                <div
                  v-if="!initLoading && !loading && token != null"
                  :style="{
                    textAlign: 'center',
                    marginTop: '12px',
                    height: '32px',
                    lineHeight: '32px',
                  }"
                >
                  <a-button @click="onLoadMore">load more</a-button>
                </div>
              </template>
              <template #renderItem="{ item }">
                <a-list-item>
                    <div v-if="item.eventSource == 'Warning'">
                    <ExclamationCircleTwoTone twoToneColor="#ebcd34" />
                    </div>
                    <div v-if="item.eventSource == 'Error'">
                    <CloseCircleTwoTone twoToneColor="#eb3434" />
                    </div>
                    <div v-if="item.eventSource != 'Warning'&&item.eventSource != 'Error'">
                    <MinusCircleTwoTone twoToneColor="#3496eb"  />
                    </div>
                  &ensp;
                  <!-- <template #actions>
                    <a key="list-loadmore-edit">edit</a>
                    <a key="list-loadmore-more">more</a>
                  </template> -->
                  <a-skeleton
                    avatar
                    :title="false"
                    :loading="!!item.loading"
                    active
                  >
                    <a-list-item-meta>
                      <template #avatar>
                        <h3>{{ item.eventSource }}</h3>
                        <h3>{{ item.id }}</h3>
                      </template>
                    </a-list-item-meta>
                    <a-list-item-meta>
                      <template #avatar>
                        <h3>{{ item.text }}</h3>
                      </template>
                    </a-list-item-meta>
                    <a-list-item-meta>
                      <template #avatar>
                        <h3>{{ item.userAgent }}</h3>
                      </template>
                    </a-list-item-meta>
                    <a-list-item-meta>
                      <template #avatar>
                        <h3>{{ item.userIp }}</h3>
                      </template>
                    </a-list-item-meta>
                    <a-list-item-meta>
                      <template #avatar>
                        <h3>{{ item.profile.name }}</h3>
                        <h3>{{ item.profile.screenName }}</h3>
                      </template>
                    </a-list-item-meta>
                    <div>{{ item.createdAt }}</div>
                  </a-skeleton>
                </a-list-item>
              </template>
            </a-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { Auth } from "@aws-amplify/auth";
import store from "../store/index.js";
import { API, LoginMechanismArray } from "aws-amplify";
import awsconfig from "../aws-exports";
import { defineComponent, onMounted, ref, nextTick } from "vue";
import {
  UserOutlined,
  DownOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  CloseCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons-vue";
import { getEvents, getEventsToken } from "../graphql/graphqlActions";
const count = 8;

const initLoading = ref(true);
const loading = ref(false);
const data = ref([]);
const list = ref([]);
const token = ref();

onMounted(() => {
  getEvents(store.state.user.organisation, 8).then((res) => {
    res.events.forEach(element => {
      element.createdAt = new Date(element.createdAt).toLocaleString()
    });
    initLoading.value = false; 
    data.value = res.events;
    list.value = res.events;
    token.value = res.nextToken;
  });
  // document.getElementById("sidebar-scroll").style.display = "block";
});

const onLoadMore = async () => {
  loading.value = true;
  list.value = data.value.concat(
    [...new Array(count)].map(() => ({
      loading: true,
      name: {},
      picture: {},
    }))
  );

  await getEventsToken(store.state.user.organisation, 8, token.value).then((res) => {
    res.events.forEach(element => {
      element.createdAt = new Date(element.createdAt).toLocaleString()
    });
    const newData = data.value.concat(res.events);
    loading.value = false;
    data.value = newData;
    list.value = newData;
    token.value = res.nextToken;
    nextTick(() => {
      // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
      // In real scene, you can using public method of react-virtualized:
      // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
      window.dispatchEvent(new Event("resize"));
    });
  });
};
</script>
<style scoped>
.demo-loadmore-list {
  min-height: 350px;
}
</style>
