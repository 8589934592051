import { createRouter, createWebHistory } from "vue-router";

// Default Pages
import Dashboard from "../views/Dashboard.vue";
// Component Pages
import Login from "../views/Login.vue"
import AppControl from "../views/Application-Control.vue";
import InstManagement from "../views/Instance-Management.vue";
import events from "../views/Events.vue";
import UserManage from "../views/User-Management.vue";
import Profile from "../views/Profile-Management.vue";
import ConfigManage from "../views/Config-Management.vue";
import SRTManage from "../views/SRT-Management.vue";


import store from '../store/index.js'
import { Hub } from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import { AuthState} from "@aws-amplify/ui-components";

var appname = " - Virtual Eye Headless MCR";
let user;

getUser().then(async (user) => {
  if (user) {
    await store.dispatch("notifications/subscribeNotifications", null, { root: true });
    // router.push({ path: 'this.$router.history.current.path' });
  }
});

export async function getUser() {
  return Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('user/setUser', data);
      store.commit('user/setAuthState', AuthState.SignedIn);
      if(localStorage.getItem("rolePref")){
        store.commit("user/updateSelectedRole", localStorage.getItem("rolePref"));
      }
      return data;
    }
  }).catch(() => {
    store.commit('user/setUser', null);
    return null;
  });
}

Hub.listen("auth", async (data) => {
  if (data.payload.event === 'signOut') {
    user = null;
    store.commit('user/setUser', null);
     ({ path: '/login' });
  } else if (data.payload.event === 'signIn') {
    user = await getUser();
    await store.dispatch("user/loginUser", user);
    await store.dispatch("notifications/subscribeNotifications", null, { root: true });
    router.push({ path: '/instance-management' });
  }
});

//

const routes = [
  // Routes
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { hideSidebar: true }
  },
  {
    path: "/",
    name: "Headless Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true, hideSidebar: false },
  },
  {
    path: "/application-control",
    name: "Application Control",
    component: AppControl,
    meta: { requiresAuth: true, hideSidebar: false },
  },
  {
    path: "/user-management",
    name: "User Management",
    component: UserManage,
    meta: { requiresAdminAuth: true, hideSidebar: false },
  },
  {
    path: "/config-management",
    name: "Config Management",
    component: ConfigManage,
    meta: { requiresAdminAuth: true, hideSidebar: false },
  },
  {
    path: "/srt-management",
    name: "SRT Management",
    component: SRTManage,
    meta: { requiresAdminAuth: true, hideSidebar: false },
  },
  {
    path: "/profile",
    name: "Profile Management",
    component: Profile,
    meta: { requiresAuth: true, hideSidebar: false },
  },
  {
    path: "/instance-management",
    name: "Instance Management",
    component: InstManagement,
    meta: { requiresAuth: true, hideSidebar: false },
  },
  {
    path: "/events",
    name: "Events",
    component: events,
    meta: { requiresAuth: true, hideSidebar: false },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  // Components Routes
  // {
  //   path: "/component/alert",
  //   name: "Valert",
  //   component: Valert,
  //   meta: { title: "Alert" + appname },
  // },
  // {
  //   path: "/component/accordion",
  //   name: "Vaccordion",
  //   component: Vaccrodion,
  //   meta: { title: "Accordion" + appname },
  // },
  // {
  //   path: "/component/badge",
  //   name: "Vbadge",
  //   component: Vbadges,
  //   meta: { title: "Badge" + appname },
  // },
  // {
  //   path: "/component/breadcumb",
  //   name: "Vbreadcumb",
  //   component: Vbreadcumb,
  //   meta: { title: "Breadcumb" + appname },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "exact-active",
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await getUser();
    if (!user) {
      return next({
        path: '/login'
      });
    }
    return next()
  }
  if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    user = await getUser();
    try {
      let userString = await store.state.user.selectedRole;
      if (!userString.includes("Admin")) {
        return next({
          path: '/'
        });
      }
      return next()
    } catch {
      return next({
        path: '/'
      });
    }
  }
  return next()
});

export default router;
