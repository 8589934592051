import { createStore } from 'vuex'

import userModule from './modules/UserModule'
import notificationsModule from './modules/NotificationsModule'

export default createStore({ 
    modules: {
        user: userModule,
        notifications: notificationsModule
    }
})