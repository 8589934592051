<template>
  <Teleport to="#body-scroll">
    <div class="vld-parent">
      <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :loader="'dots'" :color="'#36a8dd'" />
    </div>
  </Teleport>
  <div class="accordion h-auto p-3">
    <div class="w-full">
      <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">
        Instance Management
      </h1>
      <div class="flex-top" style="display: flex">
        <div style="width: 50%; float: left">
          <p class="mt-1 text-sm font-normal text-gray-400">
            Start, Stop and Reboot existing EC2s available to your account
          </p>
        </div>
        <div class="demo-dropdown-wrap" style="margin-left: auto" @mouseover="checkBoxListen()">
          <a-dropdown>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1" :disabled="validated != 1" @click="multiInstanceConfirm('start')">
                  Start instances
                </a-menu-item>
                <a-menu-item key="2" :disabled="validated != 2" @click="multiInstanceConfirm('reboot')">
                  Restart instances
                </a-menu-item>
                <a-menu-item key="3" :disabled="validated != 2" @click="multiInstanceConfirm('stop')">
                  Stop instances
                </a-menu-item>
                <a-menu-item key="4" :disabled="maxSelected" @click="selectall()">
                  Select all instances
                </a-menu-item>
                <a-menu-item key="5" :disabled="checkedids.length == 0" @click="deselectall()">
                  Deselect all instances
                </a-menu-item>
                <a-menu-item v-if="isAdmin" key="6" :disabled="checkedids.length == 0 || !isAdmin" @click="sendRSYNC()">
                  Update Selected
                </a-menu-item>
              </a-menu>
            </template>
            <a-button>
              Instance state
              <DownOutlined />
            </a-button>
          </a-dropdown>
          <a-button @click="refreshInstances">
            <template #icon>
              <ReloadOutlined />
            </template>
          </a-button>
        </div>
      </div>
    </div>
    <div class="grid lg:grid-cols-1 grid-col-1 gap-4 mt-5">
      <!-- default alert  -->
      <div class="
                    card
                    col-span-1
                    grid grid-cols-1
                    gap-5
                    bg-white
                    dark:bg-gray-800
                    w-full
                    rounded-md
                    p-5
                    shadow
                  ">
        <div class="accordion-packed">
          <h2 style="display: flex" class="font-semibold dark:text-gray-200">
            Instance Management
            <!-- <button
              type="submit"
              class="btn"
              @click="getInstances()"
            >
              &#x27F3;
            </button> -->
            <label style="margin-left: auto" class="switch">
              <input type="checkbox" />
              <span class="slider round" @click="listselect()"></span>
            </label>
          </h2>
          <div v-if="Object.keys(data).length === 0 && !isLoading"
            style="font-size: large;color: #cdd3d2; justify-content: center; display: flex">
            No EC2 Instances Detected
          </div>
          <div v-if="listview == 0" class="wrapper mt-5">
            <div id="app">
              <ul class="card-list-IM">
                <li v-for="(user, index) in data" v-bind:id="index" class="card-item rounded-md box-border"
                  id="{{index}}">
                  <div id="Header" style="display: inline-flex; width: 100%">
                    <div style="white-space: nowrap; overflow: hidden;">
                      <input type="checkbox" v-bind:id="index" v-bind:value="user" v-model="checkedNames" />
                      {{ user.name }} <br />{{ user.id }}
                    </div>
                    <div id="menu-icons" style="
                                                                                          justify-content: end;
                                                                                          display: flex;
                                                                                          margin-left: auto;
                                                                                          /* width: inherit; */
                                                                                          
                                                                                          font-size: large;
                                                                                          color: #48b5e7;
                                                                                        ">
                      <div id="menu-slider">
                        <Icon icon="streamline:interface-signal-square-heart-line-stats-beat-square-graph"
                          style="padding-right: 2px" @click="retrieveGraphs(user.id)" />
                      </div>
                    </div>
                  </div>


                  <img class="squared" style="box-shadow: none !important" />
                  <div id="instText" style="padding-top: 5%">
                    State: {{ user.currstate.toUpperCase() }}
                    <div id="buttonDiv" style="padding-top: 5%">
                      <a-button class="buttons" v-if="user.currstate === 'stopped'"
                        @click="postInstances('start', user.id)">
                        Start
                      </a-button>

                      <a-button :disabled=true class="buttons" style="background: #bebdbb !important" v-if="user.currstate !== 'running' &&
                        user.currstate !== 'stopped'
                        ">
                        Please Wait
                      </a-button>

                      <a-button class="buttons" style="background: #f74343 !important" v-if="user.currstate === 'running'"
                        @click="postInstances('stop', user.id)">
                        Stop
                      </a-button>

                      <a-button class="buttons" style="background: #f7b143 !important" v-if="user.currstate === 'running'"
                        @click="postInstances('reboot', user.id)">
                        Restart
                      </a-button>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- <button @click="addCard">Add card</button> -->
            </div>
          </div>
          <div v-if="listview == 1" class="wrapper mt-5">
            <div id="app">
              <table style="width: 100%">
                <tr>
                  <th>Select</th>
                  <th>Name</th>
                  <th>ID</th>
                  <th>Privdns</th>
                  <th>Privip</th>
                  <th>Pubdns</th>
                  <th>Curr. State</th>
                  <th>CMD</th>
                </tr>
                <tr v-for="(card, index) in data" v-bind:id="index" class="card-item rounded-md box-border">
                  <td style="
                                text-align: center;
                                vertical-align: middle;
                                border: 1px solid grey;
                                border-collapse: collapse;
                              ">
                    <input type="checkbox" v-bind:id="index" v-bind:value="card" v-model="checkedNames" />
                  </td>

                  <td style="
                                text-align: center;
                                vertical-align: middle;
                                border: 1px solid grey;
                                border-collapse: collapse;
                              ">
                    <div id="clickable">
                      {{ card.name }}
                    </div>
                  </td>
                  <td style="
                                text-align: center;
                                vertical-align: middle;
                                border: 1px solid grey;
                                border-collapse: collapse;
                              ">
                    {{ card.id }}
                  </td>

                  <td style="
                                text-align: center;
                                vertical-align: middle;
                                border: 1px solid grey;
                                border-collapse: collapse;
                              ">
                    <div>
                      {{ card.privdns }}
                    </div>
                  </td>

                  <td style="
                                text-align: center;
                                vertical-align: middle;
                                border: 1px solid grey;
                                border-collapse: collapse;
                              ">
                    {{ card.privip }}
                  </td>
                  <td style="
                                text-align: center;
                                vertical-align: middle;
                                border: 1px solid grey;
                                border-collapse: collapse;
                              ">
                    {{ card.pubdns }}
                  </td>

                  <td style="
                                text-align: center;
                                vertical-align: middle;
                                border: 1px solid grey;
                                border-collapse: collapse;
                              ">
                    {{ card.currstate }}
                  </td>
                  <td style="
                                text-align: center;
                                vertical-align: middle;
                                border: 1px solid grey;
                                border-collapse: collapse;
                              ">
                    <div id="buttonDiv" style="padding-top: 5%">
                      <a-button class="buttons" v-if="card.currstate === 'stopped'"
                        @click="postInstances('start', card.id)">
                        Start
                      </a-button>

                      <a-button class="buttons" style="background: #bebdbb !important" v-if="card.currstate !== 'running' &&
                        card.currstate !== 'stopped'
                        ">
                        Please Wait
                      </a-button>

                      <a-button class="buttons" style="background: #f74343 !important" v-if="card.currstate === 'running'"
                        @click="postInstances('stop', card.id)">
                        Stop
                      </a-button>

                      <a-button class="buttons" style="background: #f7b143 !important" v-if="card.currstate === 'running'"
                        @click="postInstances('reboot', card.id)">
                        Restart
                      </a-button>
                    </div>
                  </td>
                </tr>
              </table>
              <!-- <button @click="addCard">Add card</button> -->
            </div>
          </div>
        </div>
        <div>
          <a-modal v-model:visible="visible" title="Confirmation required" @ok="handleOk">
            <p>
              It appears you are attempting to
              <b> {{ endType }} </b> instances:
            </p>
            <p>{{ checkedids }}</p>
            <p>
              Please review this change and click <i>"confirm"</i> to confirm
            </p>
            <template #footer>
              <a-button key="back" @click="handleCancel">Return</a-button>
              <a-button key="submit" @click="postMultipleInstances(endType)">Confirm</a-button>
            </template>
          </a-modal>
        </div>

        <div>
          <a-modal v-model:visible="picvisible" title="Instance statistics" @ok="handleOkPic">
            <loading v-model:active="isLoadingPic" :can-cancel="false" :is-full-page="false" :loader="'dots'"
              :color="'#36a8dd'" />
            <div v-if="!isLoadingPic">
              <img v-bind:src="'data:image/jpeg;base64,' + instanceImages.CPU" width="450" height="450" />
              <a-divider style="height: 2px; background-color: #36a8dd" />
              <img v-bind:src="'data:image/jpeg;base64,' + instanceImages.NetIn" width="450" height="450" />
              <a-divider style="height: 2px; background-color: #36a8dd" />
              <img v-bind:src="'data:image/jpeg;base64,' + instanceImages.NetOut" width="450" height="450" />
            </div>
            <template #footer>
              <a-button key="back" @click="handleCancelPic">Return</a-button>
            </template>
          </a-modal>
        </div>


      </div>
    </div>
    <ul id="menu">
      <li v-for="(instData, key) in selectedInst" v-bind:id="key" class="menu-item" id="{{key}}">
        {{ key }} : {{ instData }}
      </li>
    </ul>
    <!-- v-for="(user, index) in data"
                  v-bind:id="index"
                  class="card-item rounded-md box-border"
                  id="{{index}}" -->

    <div id="out-click"></div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import Auth from "@aws-amplify/auth";
// import { API ,PubSub} from "aws-amplify";
import Loading from "vue-loading-overlay";
import awsconfig from "../aws-exports";
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import store from '../store';
import { UserOutlined, DownOutlined, ReloadOutlined } from "@ant-design/icons-vue";
import { getEC2, ec2Actions, getMyProfile, ec2PushMess, graphEC2 } from '../graphql/graphqlActions'
// import AppAccordion from "../../components/AppAccordion.vue";
// import MenuAccordion from "../../components/MenuAccordion.vue";

// watch: {
//   checkedNames: function (value) {
//     // If "pageData" ever changes, then we will console log its new value.
//     this.checkedNames.length == Object.keys(this.data).length
//       ? (this.maxSelected = true)
//       : (this.maxSelected = false);

//     this.checkBoxListen();
//   },
// },

const endType = ref(null);
const listview = ref(0);
const data = ref({});
const instances = ref(null);
const isLoading = ref(true);
const isLoadingPic = ref(true);
const selectedInst = ref({});
const checkedNames = ref([]);
const checkedids = ref([]);
const validated = ref(0);
const maxSelected = ref(false);
const intervalID = ref(null);
const isAdmin = ref(store.state.user.isAdmin);
const visible = ref(false);
const picvisible = ref(false);
const instanceImages = ref({});

onMounted(async () => {
  document.getElementById("sidebar-scroll").style.display = "block";
  intervalID.value = setInterval(async () => {
    await getInstances();
    checkedNames.value.length == Object.keys(data.value).length ? (maxSelected.value = true) : (maxSelected.value = false);
    checkBoxListen();
  }, 15000);
  await getInstances();
})

const showModal = () => {
  visible.value = true;
};

const handleOk = (e) => {
  visible.value = false;
};

const handleCancel = () => {
  visible.value = false;
};

const showPicture = () => {
  picvisible.value = true;
};

const handleOkPic = (e) => {
  instanceImages.value = {}
  picvisible.value = false;
};

const handleCancelPic = () => {
  instanceImages.value = {}
  picvisible.value = false;
};

function refreshInstances() {
  isLoading.value = true;
  getInstances();
}

async function retrieveGraphs(inst) {
  isLoadingPic.value = true;
  showPicture();
  instanceImages.value = await graphEC2(inst, store.state.user.organisation)
  isLoadingPic.value = false;
}


async function sendRSYNC() {
  let action = { "RSYNC": true }
  await ec2PushMess(JSON.stringify(action), "SETTINGS", checkedids.value, store.state.user.organisation)
  deselectall()
}

function listselect() {
  if (listview.value == 0) {
    listview.value = 1;
  } else {
    listview.value = 0;
    return;
  }
};

function selectall() {
  var objectArray = Object.keys(data.value).map((key) => {
    return data.value[key];
  });
  checkedNames.value = objectArray;
};

function deselectall() {
  checkedNames.value = [];
  checkedids.value = [];
};

function multiInstanceConfirm(end) {
  endType.value = end;
  showModal();
};

function sort() {
  let ordered = Object.keys(data.value)
    .sort(new Intl.Collator("en", { numeric: true }).compare)
    .reduce((obj, key) => {
      obj[key] = data.value[key];
      return obj;
    }, {});
  data.value = ordered;
};

function addCard() {
  cards.push("new-card");
};

function postInstances(endpoint, id) {
  ec2Actions(endpoint, id, store.state.user.organisation).then((response) => {
    checkedNames.value = [];
    getInstances();
  })
    .catch(function (error) {
      console.log(error);
    });
  // var axios = require("axios");
  // if (this.checkedNames.length === 0) {
  // var config = {
  //   method: "post",
  //   url: awsconfig.Lambda.stage + endpoint,
  //   data: {
  //     instances: [id],
  //   },
  //   headers: {
  //     Authorization:
  //       "Bearer " + this.userData.signInUserSession.idToken.jwtToken,
  //   },
  // };
  // axios(config)
  //   .then((response) => {
  //     this.checkedNames = [];
  //     this.getInstances();
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
};

function postMultipleInstances(endpoint) {
  var instanceList = String(checkedids.value)
  console.log(instanceList)
  console.log(endpoint)
  console.log(checkedids.value)
  ec2Actions(endpoint, instanceList, store.state.user.organisation).then((response) => {
    checkedNames.value = [];
    getInstances();
    handleOk();
  })
    .catch(function (error) {
      console.log(error);
    });
  // var axios = require("axios");
  // var config = {
  //   method: "post",
  //   url: awsconfig.Lambda.stage + endpoint,
  //   data: {
  //     instances: this.checkedids,
  //   },
  //   headers: {
  //     Authorization:
  //       "Bearer " + this.userData.signInUserSession.idToken.jwtToken,
  //   },
  // };
  // axios(config)
  //   .then((response) => {
  //     this.checkedNames = [];
  //     this.getInstances();
  //     this.handleOk();
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
};

function checkNames(name) {
  try {
    checkedids.value.push(name);
  } catch (e) { }
};

function checkBoxListen() {
  try {
    let see = checkedNames.value.map((a) => a.currstate);
    checkedids.value = checkedNames.value.map((a) => a.id);
    if (new Set(see).size === 1) {
      switch (see[0]) {
        case "stopped":
          validated.value = 1;
          break;
        case "pending":
          validated.value = 0;
          break;
        case "running":
          validated.value = 2;
          break;
        case "starting":
          validated.value = 0;
          break;
      }
    } else {
      validated.value = 0;
    }
  } catch (e) { }
};

function clickListen(id, inst) {
  try {
    const clickable = document.getElementById(id);
    // console.log("getting: " + id)
    const menu = document.getElementById("menu");
    const outClick = document.getElementById("out-click");
    clickable.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      menu.style.top = `${e.clientY}px`;
      menu.style.left = `${e.layerX}px`;
      menu.classList.add("show");
      outClick.style.display = "block";
      selectedInst.value = inst;
    });

    outClick.addEventListener("click", () => {
      menu.classList.remove("show");
      outClick.style.display = "none";
    });
  } catch (e) { }
};
async function getInstances() {
  let prof = await getMyProfile()

  await getEC2(JSON.parse(prof.ec2s), store.state.user.organisation).then((response) => {
    var localData = {};
    // console.log(response);
    instances.value = response;
    instances.value.Reservations.forEach((element) => {
      element.Instances.forEach((i) => {
        let inst = {
          id: i.InstanceId,
          name: i.Tags.find(o => o.Key == "Name").Value,
          privip: i.PrivateIpAddress,
          currstate: i.State.Name,
          privdns: i.PrivateDnsName,
          pubdns: i.PublicDnsName,
        };
        localData[inst.name] = inst;
      });
    });
    data.value = localData;
    sort((a, b) => a - b);
  })
    .catch(function (error) {
      console.log(error);
    });
  isLoading.value = false;
  for (let key of Object.keys(data.value)) {
    clickListen(data.value[key].name, data.value[key]);
  }
};

onUnmounted(() => clearInterval(intervalID.value))

</script>
