<template>
  <div class="dashboard p-4">
    <nav class="flex" aria-label="Breadcrumb">
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <a
            href="#"
            class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
          >
            <svg
              class="mr-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Dashboard
          </a>
        </li>
      </ol>
    </nav>
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">Dashboard</h1>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5">
      <!-- card  -->
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex">
        <div class="p-2 max-w-sm">
          <div
            class="bg-orange-200 rounded-full w-14 h-14 text-lg p-3 text-orange-600 mx-auto"
          >
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#d67f1a"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M21.5 12H16c-.7 2-2 3-4 3s-3.3-1-4-3H2.5" />
                <path
                  d="M5.5 5.1L2 12v6c0 1.1.9 2 2 2h16a2 2 0 002-2v-6l-3.4-6.9A2 2 0 0016.8 4H7.2a2 2 0 00-1.8 1.1z"
                />
              </svg>
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">Alerts</p>
          <h2 class="font-normal text-gray-400 text-md mt-1">No current Alerts</h2>
        </div>
      </div>
      <!-- end card -->
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex">
        <div class="p-2 max-w-sm">
          <div
            class="bg-green-200 rounded-full w-14 h-14 text-lg p-3 text-green-600 mx-auto"
          >
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#15b24c"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
                <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
                <line x1="6" y1="6" x2="6.01" y2="6"></line>
                <line x1="6" y1="18" x2="6.01" y2="18"></line>
              </svg>
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            Running instances
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">No Instances Running</h2>
        </div>
      </div>
      <!-- end card -->
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex">
        <div class="p-2 max-w-sm">
          <div class="bg-red-200 rounded-full w-14 h-14 text-lg p-3 text-red-600 mx-auto">
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#dc2626"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                ></path>
                <line x1="12" y1="9" x2="12" y2="13"></line>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">Urgent</p>
          <h2 class="font-normal text-gray-400 text-md mt-1">Urgent Issues</h2>
        </div>
      </div>
      <!-- end card -->
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex">
        <div class="p-2 max-w-sm">
          <div
            class="bg-purple-200 rounded-full w-14 h-14 text-lg p-3 text-purple-600 mx-auto"
          >
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="30px"
                height="30px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="currentColor">
                  <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                  <path
                    d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z"
                  />
                </g>
              </svg>
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            Account history
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">
            History of actions and events
          </h2>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end wrapper card -->
    <!-- <div class="mt-5 lg:flex block lg:gap-4">
      <div class="bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200">
          PlaceHolder Graph
        </h2>
        <br />
        <apexchart
          width="100%"
          height="480"
          type="line"
          :options="optionsLine"
          :series="seriesLine"
        ></apexchart>
      </div>
      <div class="bg-white dark:bg-gray-800 rounded-md p-5 w-96 shadow">
        <h2 class="dark:text-gray-200">Games ({{ totalGames }})</h2>
  
        <p class="font-bold font-lg mt-5 dark:text-gray-200">
          In Progress ({{ totalGamesInProgress }})
          Games Today ({{ date }})
        </p> -->
        <!-- <div id="inProgress" class="mt-4 grid grid-cols-1 gap-4">
          <li
            v-for="(card, index) in gameoptions"
            v-bind:id="index"
            class="w-full box-border flex gap-4"
          >
            <button class="w-full box-border flex gap-4">
              <span>
                <img
                  :src="`../assets/img/team/${card.away}.png`"
                  style="background-color: rgb(199, 223, 251)"
                  alt=""
                  width="50"
                  height="50"
                  class="rounded-full"
                />
              </span>
              <span
                class="text-left"
                style="margin: auto; width: 50%; text-align: center"
              >
                <h1 class="font-bold text-xl dark:text-gray-200">
                  {{ card.away }} @ {{ card.home }}
                </h1>
                <p class="dark:text-gray-200">{{ card.gameDate }}</p>
              </span>
              <span style="margin-left: auto; margin-right: 0">
                <img
                  :src="`../assets/img/team/${card.home}.png`"
                  style="background-color: rgb(199, 223, 251)"
                  alt=""
                  width="50"
                  height="50"
                  class="rounded-full"
                />
              </span>
            </button>
          </li> -->

          <!-- <button class="w-full box-border flex gap-4">
            <span>
              <img
                src="../assets/img/user1.png"
                alt=""
                width="50"
                height="50"
                class="rounded-full"
              />
            </span>
            <span class="text-left">
              <h1 class="font-bold text-xl dark:text-gray-200">AAA @ AAA</h1>
              <p class="dark:text-gray-200">18:00:00 UTC</p>
            </span>
            <span>
              <img
                src="../assets/img/user1.png"
                alt=""
                width="50"
                height="50"
                class="rounded-full"
              />
            </span>
          </button> -->
        <!-- </div> -->
        <!-- <div class="mt-4 grid grid-cols-1 gap-4">
          <p class="font-bold font-lg mt-5 dark:text-gray-200">Scheduled</p>
          <div id="Scheduled">
          </div>
        </div> -->
      <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import axios from "axios";
import moment from "moment";
import tz from "moment-timezone";
// import teamNames from "../assets/img/team/TeamInfo";

export default {
  name: "Dashboard",
  data() {
    return {
      // for more guide apexchart.js
      // https://apexcharts.com/docs/chart-types/line-chart/
      // gameDayInfo: null,
      // gameoptions: null,
      // totalGames: null,
      // totalGamesInProgress: null,
      // date: null,
      // timezones: [],

      // chart data line
      optionsLine: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [
            2015,
            2016,
            2017,
            2018,
            2019,
            2020,
            2021,
            2022,
            2015,
            2016,
            2017,
            2018,
            2019,
            2020,
            2021,
            2022,
            2015,
            2016,
            2017,
            2018,
            2019,
            2020,
            2021,
            2022,
            2015,
            2016,
            2017,
            2018,
            2019,
            2020,
            2021,
            2022,
            2015,
            2016,
            2017,
            2018,
            2019,
            2020,
            2021,
            2022,
            2015,
            2016,
            2017,
            2018,
            2019,
            2020,
            2021,
            2022,
          ],
        },
        yaxis: {
          title: {
            text: "Count",
          },
        },
      },
      colors: ["#16A34A"],
      stroke: {
        width: 20,
        curve: "smooth",
      },
      seriesLine: [
        {
          name: "series-1",
          data: [
            30,
            40,
            45,
            50,
            49,
            60,
            70,
            91,
            30,
            40,
            45,
            50,
            49,
            60,
            70,
            91,
            300,
            20,
            91,
            300,
            20,
            49,
            60,
            70,
            91,
            30,
            40,
            45,
            50,
            33,
            55,
            49,
            33,
            55,
            49,
            60,
            70,
            91,
            300,
            20,
            33,
            55,
            49,
            60,
            70,
            60,
            70,
            91,
          ],
        },
        {
          name: "series-2",
          data: [
            300,
            20,
            33,
            55,
            49,
            60,
            70,
            91,
            300,
            20,
            33,
            55,
            49,
            60,
            70,
            91,
            300,
            20,
            33,
            55,
            49,
            60,
            70,
            91,
            300,
            20,
            33,
            55,
            49,
            60,
            70,
            91,
            300,
            20,
            33,
            55,
            49,
            60,
            70,
            91,
            300,
            20,
            33,
            55,
            49,
            60,
            70,
            91,
          ],
        },
      ],
    };
    // end chart data line
  },
  components: {
    Icon,
  },
  methods: {
    // async getGames() {
    //   await axios
    //     .get(
    //       "https://statsapi.mlb.com/api/v1/schedule?sportId=1&date=04/27/2022"
    //     )
    //     .then((response) => {
    //       this.gameDayInfo = response.data;
    //       this.createGameSchedule(response.data);
    //     });
    // },
    // setNewTimeZoneOption(selectedOption) {
    //   // this.config.placeholder = selectedOption.value;
    // },
    // async getGames(home, away) {
    //   // let gamedata = {};
    //   // gamedata = {
    //   //   home: teamNames.teams.find(({ id }) => id === home).abbreviation,
    //   //   away: teamNames.teams.find(({ id }) => id === away).abbreviation,
    //   // };
    //   // // await axios
    //   // //   .get("http://statsapi.mlb.com/api/v1.1/game/" + gamePK + "/feed/live")
    //   // //   .then((response) => {
    //   // //       gamedata = {
    //   // //         home: response.data.gameData.teams.home.abbreviation,
    //   // //         away: response.data.gameData.teams.away.abbreviation,
    //   // //       };
    //   // //   });

    //   // return gamedata;
    // },
    // async getSchedule(date) {
    //   // this.timezones = moment.tz.zonesForCountry("US");
    //   // let gamedata = {};
    //   // await axios
    //   //   .get("http://statsapi.mlb.com/api/v1/schedule?sportId=1&date=" + date)
    //   //   .then(async (response) => {
    //   //     this.gameDayInfo = response.data.dates[0];
    //   //     this.gameoptions = response.data.dates[0].games;
    //   //     //console.log(response.data)
    //   //     for (let key of Object.keys(this.gameoptions)) {
    //   //       //console.log(this.gameoptions[key].gamePk)
    //   //       this.gameoptions[key].gameDate = moment(this.gameoptions[key].gameDate)
    //   //         .tz("America/New_York")
    //   //         .format("h:mma z");
    //   //       let gameInfo = await this.getGames(
    //   //         this.gameoptions[key].teams.home.team.id,
    //   //         this.gameoptions[key].teams.away.team.id
    //   //       );
    //   //       Object.assign(this.gameoptions[key], gameInfo);
    //   //     }
    //   //   });
    //   // return gamedata;
    // },
  },
  mounted() {
    this.$router.push("/instance-management");
    // document.getElementById("sidebar-scroll").style.display = "block";
    // //this.getGames();
    // let dateNow = moment().tz("America/New_York").format("MM/DD/YYYY");
    // this.date = dateNow.slice(0, dateNow.lastIndexOf("/"));
    // this.getSchedule(dateNow);
  },
};
</script>
